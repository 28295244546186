import {
  ALL_PAIRS_V3,
  ALL_TOKENS_V3,
  FETCH_TICKS,
  // GLOBAL_CHART_V3,
  GLOBAL_DATA_V3,
  GLOBAL_TRANSACTIONS_V3,
  IS_PAIR_EXISTS_V3,
  IS_TOKEN_EXISTS_V3,
  BNB_PRICE_V3,
  PAIRS_FROM_ADDRESSES_V3,
  PAIR_CHART_V3,
  PAIR_FEE_CHART_V3,
  PAIR_TRANSACTIONS_v3,
  TOKENS_FROM_ADDRESSES_V3,
  TOKEN_CHART_V3,
  TOP_POOLS_V3,
  TOP_POOLS_V3_TOKENS,
  TOP_TOKENS_V3,
  PRICES_BY_BLOCK_V3,
} from '../apollo/fusionQueries'
import {
  get2DayPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  getChartData,
  getGlobalDataV1,
  //getGlobalDataV1Day,
  getPercentChange,
  getSecondsOneDay,
  getTokenChartData,
  getTokenPairsFusion,
  getTokenPairsV1,
  splitQuery,
  updateNameData,
} from './analyticsHelper'
import dayjs from 'dayjs'
import { Token } from '@uniswap/sdk-core'
import { TickMath, tickToPrice } from '@uniswap/v3-sdk'
import { JSBI } from 'thena-sdk'
import keyBy from 'lodash/keyBy'
import {
  FILTERED_TRANSACTIONS,
  PAIRS_BULK1,
  PAIRS_CURRENT_QUICK,
  PAIRS_HISTORICAL_BULK,
  TOKENS_FROM_ADDRESSES_V2,
  TOKEN_INFO,
  TOKEN_INFO_OLD,
  TOKEN_TOP_DAY_DATAS,
} from '../apollo/queries'
import client, { fusionClient } from '../apollo/client'
import { AnalyticVersions, STABLE_FEE, TXN_TYPE, VOLATILE_FEE, defaultChainId, showV1Analytics } from '../config/constants'
import { SWAPX_GLOBAL_CHART } from '../apollo/swapx_queries'
import swapxClient from '../apollo/swapx_client'

export const FEEPERCENT = 0.002

//Global

export async function getGlobalDataTotal() {
  const [fusionData, v1Data] = await Promise.all([getGlobalDataFusion(), showV1Analytics ? getGlobalDataV1() : {}])

  // const totalLiquidityUSD = fusionData.totalLiquidityUSD + v1Data.totalLiquidityUSD
  // const prevTotalLiquidityUSD = fusionData.prevTotalLiquidityUSD + v1Data.prevTotalLiquidityUSD
  // const liquidityChangeUSD = getPercentChange(totalLiquidityUSD, prevTotalLiquidityUSD)
  const totalLiquidityUSD = fusionData.totalLiquidityUSD
  const prevTotalLiquidityUSD = fusionData.prevTotalLiquidityUSD
  const liquidityChangeUSD = getPercentChange(totalLiquidityUSD, prevTotalLiquidityUSD)

  // const volumeUSD = fusionData.volumeUSD + v1Data.volumeUSD
  // const totalVolume = fusionData.totalVolumeUSD + v1Data.volumeUSD
  // const prevVolumeUSD = fusionData.prevVolumeUSD + v1Data.prevVolumeUSD
  // const volumeChange = getPercentChange(volumeUSD, prevVolumeUSD)
  const volumeUSD = fusionData.volumeUSD
  const totalVolume = fusionData.totalVolumeUSD
  const prevVolumeUSD = fusionData.prevVolumeUSD
  const volumeChange = getPercentChange(volumeUSD, prevVolumeUSD)

  // const feesUSD = fusionData.feesUSD + v1Data.feesUSD
  // const prevFeesUSD = fusionData.prevFeesUSD + v1Data.prevFeesUSD
  // const feesChange = getPercentChange(feesUSD, prevFeesUSD)
  const feesUSD = fusionData.feesUSD
  const prevFeesUSD = fusionData.prevFeesUSD
  const feesChange = getPercentChange(feesUSD, prevFeesUSD)

  return {
    [AnalyticVersions.v1]: v1Data,
    [AnalyticVersions.fusion]: fusionData,
    [AnalyticVersions.total]: {
      totalLiquidityUSD,
      prevTotalLiquidityUSD,
      liquidityChangeUSD,
      totalVolume,
      volumeUSD,
      prevVolumeUSD,
      volumeChange,
      feesUSD,
      prevFeesUSD,
      feesChange,
    },
  }
}

function calcWeeklyData(_value, _new, _count) {
  return _value ? (_value * (_count <= 1 ? _count : _count - 1) + Number(_new)) / _count : Number(_new)
}

function getDurationPercent(current, prev, old, key) {
  const value = Number(current?.[key] || 0) - Number(prev?.[key] || 0)
  const preValue = Number(prev?.[key] || 0) - Number(old?.[key] || 0)
  const change = getPercentChange(value, preValue)
  return [value, change]
}

export async function getGlobalDataFusion() {
  let data = {}

  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(2, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').unix()
    const utcTwoMonthBack = utcCurrentTime.subtract(2, 'month').unix()

    // get the blocks needed for time travel queries
    const [oneDayBlock, twoDayBlock, oneWeekBackBlock, twoWeekBackBlock, oneMonthBackBlock, twoMonthBackBlock] = await getBlocksFromTimestamps(
      [utcOneDayBack, utcTwoDayBack, utcOneWeekBack, utcTwoWeekBack, utcOneMonthBack, utcTwoMonthBack],
      500,
    )

    const [dataCurrent, dataOneDay, dataTwoDay, dataOneWeek, dataTwoWeek, dataOneMonth, dataTwoMonth] = await Promise.all([
      fusionClient.query({
        query: GLOBAL_DATA_V3(),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(oneDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(twoDayBlock?.number),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(oneWeekBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(twoWeekBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(oneMonthBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
      fusionClient.query({
        query: GLOBAL_DATA_V3(twoMonthBackBlock?.number),
        fetchPolicy: 'network-only',
      }),
    ])

    const [statsCurrent, statsOneDay, statsTwoDay, statsOneWeek, statsTwoWeek, statsOneMonth, statsTwoMonth] = [
      dataCurrent && dataCurrent.data && dataCurrent.data.factories && dataCurrent.data.factories.length > 0 ? dataCurrent.data.factories[0] : undefined,
      dataOneDay && dataOneDay.data && dataOneDay.data.factories && dataOneDay.data.factories.length > 0 ? dataOneDay.data.factories[0] : undefined,
      dataTwoDay && dataTwoDay.data && dataTwoDay.data.factories && dataTwoDay.data.factories.length > 0 ? dataTwoDay.data.factories[0] : undefined,
      dataOneWeek && dataOneWeek.data && dataOneWeek.data.factories && dataOneWeek.data.factories.length > 0 ? dataOneWeek.data.factories[0] : undefined,
      dataTwoWeek && dataTwoWeek.data && dataTwoWeek.data.factories && dataTwoWeek.data.factories.length > 0 ? dataTwoWeek.data.factories[0] : undefined,
      dataOneMonth && dataOneMonth.data && dataOneMonth.data.factories && dataOneMonth.data.factories.length > 0 ? dataOneMonth.data.factories[0] : undefined,
      dataTwoMonth && dataTwoMonth.data && dataTwoMonth.data.factories && dataTwoMonth.data.factories.length > 0 ? dataTwoMonth.data.factories[0] : undefined,
    ]

    const liquidityOneDayChangeUSD = getPercentChange(statsCurrent ? statsCurrent.totalValueLockedUSD : 0, statsOneDay ? statsOneDay.totalValueLockedUSD : 0)
    const liquidityOneWeekChangeUSD = getPercentChange(statsCurrent ? statsCurrent.totalValueLockedUSD : 0, statsOneDay ? statsOneWeek.totalValueLockedUSD : 0)
    const liquidityOneMonthChangeUSD = getPercentChange(
      statsCurrent ? statsCurrent.totalValueLockedUSD : 0,
      statsOneDay ? statsOneMonth.totalValueLockedUSD : 0,
    )

    const [volumeOneDayUSD, volumeOneDayChange] = getDurationPercent(statsCurrent, statsOneDay, statsTwoDay, 'totalVolumeUSD')
    const [volumeOneWeekUSD, volumeOneWeekChange] = getDurationPercent(statsCurrent, statsOneWeek, statsTwoWeek, 'totalVolumeUSD')
    const [volumeOneMonthUSD, volumeOneMonthChange] = getDurationPercent(statsCurrent, statsOneMonth, statsTwoMonth, 'totalVolumeUSD')

    const [feesOneDayUSD, feesOneDayChange] = getDurationPercent(statsCurrent, statsOneDay, statsTwoDay, 'totalFeesUSD')
    const [feesOneWeekUSD, feesOneWeekChange] = getDurationPercent(statsCurrent, statsOneWeek, statsTwoWeek, 'totalFeesUSD')
    const [feesOneMonthUSD, feesOneMonthChange] = getDurationPercent(statsCurrent, statsOneMonth, statsTwoMonth, 'totalFeesUSD')

    data = {
      liquidityOneDayUSD: Number(statsCurrent.totalValueLockedUSD),
      liquidityOneWeekUSD: Number(statsOneWeek.totalValueLockedUSD),
      liquidityOneMonthUSD: Number(statsOneMonth.totalValueLockedUSD),
      liquidityOneDayChangeUSD,
      liquidityOneWeekChangeUSD,
      liquidityOneMonthChangeUSD,
      totalVolumeUSD: Number(statsCurrent.totalVolumeUSD),
      volumeOneDayUSD,
      volumeOneDayChange,
      volumeOneWeekUSD,
      volumeOneWeekChange,
      volumeOneMonthUSD,
      volumeOneMonthChange,
      feesOneDayUSD,
      feesOneDayChange,
      feesOneWeekUSD,
      feesOneWeekChange,
      feesOneMonthUSD,
      feesOneMonthChange,
    }
  } catch (e) {
    console.log(e)
  }

  return data
}

export const getBnbPrice = async () => {
  const utcCurrentTime = dayjs()

  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  let bnbPrice = 0
  let bnbPriceOneDay = 0
  let priceChangeBnb = 0

  try {
    const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
    const client = fusionClient
    const result = await client.query({
      query: BNB_PRICE_V3(),
      fetchPolicy: 'network-only',
    })
    const resultOneDay = await client.query({
      query: BNB_PRICE_V3(oneDayBlock),
      fetchPolicy: 'network-only',
    })
    const currentPrice = Number(result?.data?.bundles[0]?.bnbPriceUSD ?? 0)
    const oneDayBackPrice = Number(resultOneDay?.data?.bundles[0]?.bnbPriceUSD ?? 0)

    priceChangeBnb = getPercentChange(currentPrice, oneDayBackPrice)
    bnbPrice = currentPrice
    bnbPriceOneDay = oneDayBackPrice
  } catch (e) {
    console.log(e)
  }

  return [bnbPrice, bnbPriceOneDay, priceChangeBnb]
}

export const getChartDataFusion = async (oldestDateToFetch) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      const result = await swapxClient.query({
        query: SWAPX_GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'network-only',
      })

      // skip += 1000
      skip += 100
      data = data.concat(
        result.data.dayDatas.map((item) => {
          return { ...item, dailyVolumeUSD: Number(item.volumeUSD) }
        }),
      )
      if (result.data.dayDatas.length < 100) {
        allFound = true
      }
    }

    if (data) {
      const dayIndexSet = new Set()
      const dayIndexArray = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
        dayData.totalLiquidityUSD = Number(dayData.tvlUSD)
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].tvlUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].tvlUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      weeklyData[_start].weeklyLiquidityUSD = calcWeeklyData(weeklyData[_start].weeklyLiquidityUSD, data[i].tvlUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyFeesUSD = calcWeeklyData(weeklyData[_start].weeklyFeesUSD, data[i].feesUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export const getChartDataTotal = async () => {
  const utcEndTime = dayjs.utc()
  // const utcStartTime = utcEndTime.subtract(1, 'month')
  const utcStartTime = utcEndTime.subtract(6, 'month')
  const startTime = utcStartTime.startOf('minute').unix() - 1

  const [fusionData, v1Data] = await Promise.all([getChartDataFusion(startTime), getChartData(startTime)])
  const dailyData = fusionData.daily.map((item) => {
    const v2Item = v1Data.daily.find((v2Item) => v2Item.date === item.date)
    return {
      ...item,
      dailyVolumeUSD: (item && item.dailyVolumeUSD ? Number(item.dailyVolumeUSD) : 0) + (v2Item && v2Item.dailyVolumeUSD ? Number(v2Item.dailyVolumeUSD) : 0),
      feesUSD: (item && item.feesUSD ? Number(item.feesUSD) : 0) + (v2Item && v2Item.dailyVolumeUSD ? Number(v2Item.dailyVolumeUSD) * FEEPERCENT : 0),
      totalLiquidityUSD:
        (item && item.totalLiquidityUSD ? Number(item.totalLiquidityUSD) : 0) + (v2Item && v2Item.totalLiquidityUSD ? Number(v2Item.totalLiquidityUSD) : 0),
    }
  })
  const weeklydata = fusionData.weekly.map((item) => {
    const v2Item = v1Data.weekly.find((v2Item) => v2Item.date === item.date)
    return {
      ...item,
      weeklyVolumeUSD:
        (item && item.weeklyVolumeUSD ? Number(item.weeklyVolumeUSD) : 0) + (v2Item && v2Item.weeklyVolumeUSD ? Number(v2Item.weeklyVolumeUSD) : 0),
    }
  })

  return {
    [AnalyticVersions.v1]: v1Data,
    [AnalyticVersions.fusion]: fusionData,
    [AnalyticVersions.total]: {
      daily: dailyData,
      weekly: weeklydata,
    },
  }
}

//Tokens

export async function getTopTokensTotal(bnbPrice, bnbPrice24H) {
  try {
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const [oneDayBlock, twoDayBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcTwoDayBack], 500)
    const [fusionFormatted, v1Formatted] = await Promise.all([
      getTopTokensFusion(bnbPrice, bnbPrice24H, oneDayBlock, twoDayBlock),
      showV1Analytics ? getTopTokensV1(bnbPrice, bnbPrice24H, oneDayBlock, twoDayBlock) : [],
    ])
    const fusionTokenIds = Object.keys(fusionFormatted)
    const v1TokenIds = Object.keys(v1Formatted)
    const totalIds = fusionTokenIds.concat(v1TokenIds.filter((id) => !fusionTokenIds.includes(id)))
    const totalFormatted = totalIds
      .map((address) => {
        const fusionData = fusionFormatted[address]
        const v1Data = v1Formatted[address]
        const tvlUSD = (fusionData ? fusionData.totalLiquidityUSD : 0) + (v1Data ? v1Data.totalLiquidityUSD : 0)
        const tvlUSDOneDay = (fusionData ? fusionData.tvlUSDOneDay : 0) + (v1Data ? v1Data.tvlUSDOneDay : 0)
        const tvlUSDChange = getPercentChange(tvlUSD, tvlUSDOneDay)
        const oneDayVolumeUSD = (fusionData ? fusionData.oneDayVolumeUSD : 0) + (v1Data ? v1Data.oneDayVolumeUSD : 0)
        const twoDayVolumeUSD = (fusionData ? fusionData.twoDayVolumeUSD : 0) + (v1Data ? v1Data.twoDayVolumeUSD : 0)
        const volumeChangeUSD = getPercentChange(oneDayVolumeUSD, twoDayVolumeUSD)
        const oneDayTxns = (fusionData ? fusionData.oneDayTxns : 0) + (v1Data ? v1Data.oneDayTxns : 0)
        const twoDayTxns = (fusionData ? fusionData.twoDayTxns : 0) + (v1Data ? v1Data.twoDayTxns : 0)
        const txnChange = getPercentChange(oneDayTxns, twoDayTxns)
        return {
          id: address,
          name: formatTokenName(address, fusionData ? fusionData.name : v1Data.name),
          symbol: formatTokenSymbol(address, fusionData ? fusionData.symbol : v1Data.symbol),
          decimals: fusionData ? fusionData.decimals : v1Data.decimals,
          oneDayVolumeUSD: (fusionData ? fusionData.oneDayVolumeUSD : 0) + (v1Data ? v1Data.oneDayVolumeUSD : 0),
          volumeChangeUSD,
          oneDayTxns,
          txnChange,
          totalLiquidityUSD: tvlUSD,
          liquidityChangeUSD: tvlUSDChange,
          priceUSD: fusionData && fusionData.priceUSD > 0 ? fusionData.priceUSD : v1Data ? v1Data.priceUSD : 0,
          priceChangeUSD: fusionData && fusionData.priceChangeUSD !== 0 ? fusionData.priceChangeUSD : v1Data ? v1Data.priceChangeUSD : 0,
        }
      })
      .sort((a, b) => {
        return b.totalLiquidityUSD - a.totalLiquidityUSD
      })
      .reduce((acc, token) => {
        return { ...acc, [token.id]: token }
      }, {})

    return {
      [AnalyticVersions.v1]: v1Formatted,
      [AnalyticVersions.fusion]: fusionFormatted,
      [AnalyticVersions.total]: totalFormatted,
    }
  } catch (err) {
    console.error(err)
  }
}

export async function getTopTokensV1(bnbPrice, bnbPrice24H, oneDayBlock, twoDayBlock) {
  try {
    const currentDate = parseInt(Date.now() / 86400 / 1000) * 86400 - 86400
    const v1TopTokensIds = await client.query({
      query: TOKEN_TOP_DAY_DATAS,
      fetchPolicy: 'network-only',
      variables: { date: currentDate },
    })
    const v1TokenAddresses = v1TopTokensIds?.data?.tokenDayDatas?.reduce((accum, entry) => {
      accum.push(entry.id.slice(0, 42))
      return accum
    }, [])
    const [v1TokensCurrent, v1Tokens24, v1Tokens48] = await Promise.all([
      fetchTokensByTimeV2(undefined, v1TokenAddresses),
      fetchTokensByTimeV2(oneDayBlock.number, v1TokenAddresses),
      fetchTokensByTimeV2(twoDayBlock.number, v1TokenAddresses),
    ])
    const parsedTokensV1 = parseTokensData(v1TokensCurrent)
    const parsedTokens24V1 = parseTokensData(v1Tokens24)
    const parsedTokens48V1 = parseTokensData(v1Tokens48)
    const v1Formatted = v1TokenAddresses.map((address) => {
      const v1Current = parsedTokensV1[address]
      const v1OneDay = parsedTokens24V1[address]
      const v1TwoDay = parsedTokens48V1[address]

      let oneDayVolumeUSD =
        Number(v1Current && v1Current.tradeVolumeUSD ? v1Current.tradeVolumeUSD : 0) - Number(v1OneDay && v1OneDay.tradeVolumeUSD ? v1OneDay.tradeVolumeUSD : 0)
      let twoDayVolumeUSD =
        Number(v1OneDay && v1OneDay.tradeVolumeUSD ? v1OneDay.tradeVolumeUSD : 0) - Number(v1TwoDay && v1TwoDay.tradeVolumeUSD ? v1TwoDay.tradeVolumeUSD : 0)
      const volumeChangeUSD = getPercentChange(oneDayVolumeUSD, twoDayVolumeUSD)

      let oneDayTxns = Number(v1Current && v1Current.txCount ? v1Current.txCount : 0) - Number(v1OneDay && v1OneDay.txCount ? v1OneDay.txCount : 0)
      let twoDayTxns = Number(v1OneDay && v1OneDay.txCount ? v1OneDay.txCount : 0) - Number(v1TwoDay && v1TwoDay.txCount ? v1TwoDay.txCount : 0)
      const txnChange = getPercentChange(oneDayTxns, twoDayTxns)

      const tvlUSD = v1Current ? (v1Current.totalLiquidity ?? 0) * bnbPrice * (v1Current.derivedETH ?? 0) : 0
      const tvlUSDOneDay = v1OneDay ? (v1OneDay.totalLiquidity ?? 0) * bnbPrice24H * (v1OneDay.derivedETH ?? 0) : 0
      const tvlUSDChange = getPercentChange(tvlUSD, tvlUSDOneDay)
      let priceUSD = v1Current && v1Current.derivedETH ? v1Current.derivedETH * bnbPrice : 0
      const priceUSDOneDay = v1OneDay && v1OneDay.derivedETH ? v1OneDay.derivedETH * bnbPrice24H : 0

      const priceChangeUSD = priceUSD > 0 && priceUSDOneDay > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneDay.toString())) : 0
      if (oneDayVolumeUSD < 0.0001) {
        oneDayVolumeUSD = 0
      }
      if (priceUSD < 0.000001) {
        priceUSD = 0
      }
      return {
        id: address,
        name: formatTokenName(address, v1Current && v1Current.name ? v1Current.name : ''),
        symbol: formatTokenSymbol(address, v1Current && v1Current.symbol ? v1Current.symbol : ''),
        decimals: v1Current && v1Current.decimals ? v1Current.decimals : 18,
        oneDayVolumeUSD,
        twoDayVolumeUSD,
        volumeChangeUSD,
        oneDayTxns,
        twoDayTxns,
        txnChange,
        totalLiquidityUSD: tvlUSD,
        tvlUSDOneDay,
        liquidityChangeUSD: tvlUSDChange,
        priceUSD,
        priceChangeUSD,
      }
    })

    return v1Formatted
      .sort((a, b) => {
        return b.totalLiquidityUSD - a.totalLiquidityUSD
      })
      .reduce((acc, token) => {
        return { ...acc, [token.id]: token }
      }, {})
  } catch (err) {
    console.log('get v1 top tokens :>> ', err)
  }
}

export async function getTopTokensFusion(bnbPrice, bnbPrice24H, count = 500) {
  try {
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(2, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').unix()
    const utcTwoMonthBack = utcCurrentTime.subtract(2, 'month').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBackBlock, twoWeekBackBlock, oneMonthBackBlock, twoMonthBackBlock] = await getBlocksFromTimestamps(
      [utcOneDayBack, utcTwoDayBack, utcOneWeekBack, utcTwoWeekBack, utcOneMonthBack, utcTwoMonthBack],
      500,
    )

    const fusionTopTokensIds = await fusionClient.query({
      query: TOP_TOKENS_V3(count),
      fetchPolicy: 'network-only',
    })
    const fusionTokenAddresses = fusionTopTokensIds.data.tokens.map((el) => el.id)

    const [dataCurrent, dataOneDay, dataTwoDay, dataOneWeek, dataTwoWeek, dataOneMonth, dataTwoMonth] = await Promise.all([
      fetchTokensByTime(undefined, fusionTokenAddresses),
      fetchTokensByTime(oneDayBlock.number, fusionTokenAddresses),
      fetchTokensByTime(twoDayBlock.number, fusionTokenAddresses),
      fetchTokensByTime(oneWeekBackBlock.number, fusionTokenAddresses),
      fetchTokensByTime(twoWeekBackBlock.number, fusionTokenAddresses),
      fetchTokensByTime(oneMonthBackBlock.number, fusionTokenAddresses),
      fetchTokensByTime(twoMonthBackBlock.number, fusionTokenAddresses),
    ])

    const parsedCurrentTokens = parseTokensData(dataCurrent)
    const parsedOneDayTokens = parseTokensData(dataOneDay)
    const parsedTwoDayTokens = parseTokensData(dataTwoDay)
    const parsedOneWeekTokens = parseTokensData(dataOneWeek)
    const parsedTwoWeekTokens = parseTokensData(dataTwoWeek)
    const parsedOneMonthTokens = parseTokensData(dataOneMonth)
    const parsedTwoMonthTokens = parseTokensData(dataTwoMonth)

    const fusionFormatted = fusionTokenAddresses.map((address) => {
      const current = parsedCurrentTokens[address]
      const oneDay = parsedOneDayTokens[address]
      const twoDay = parsedTwoDayTokens[address]
      const oneWeek = parsedOneWeekTokens[address]
      const twoWeek = parsedTwoWeekTokens[address]
      const oneMonth = parsedOneMonthTokens[address]
      const twoMonth = parsedTwoMonthTokens[address]

      const manageUntrackedVolume = current ? (+current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
      const manageUntrackedTVL = current ? (+current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''

      const [volumeOneDayUSD, volumeOneDayChangeUSD] = getDurationPercent(current, oneDay, twoDay, manageUntrackedVolume)
      const [volumeOneWeekUSD, volumeOneWeekChangeUSD] = getDurationPercent(current, oneWeek, twoWeek, manageUntrackedVolume)
      const [volumeOneMonthUSD, volumeOneMonthChangeUSD] = getDurationPercent(current, oneMonth, twoMonth, manageUntrackedVolume)

      let oneDayTxns = Number(current && current.txCount ? current.txCount : 0) - Number(oneDay && oneDay.txCount ? oneDay.txCount : 0)
      let twoDayTxns = Number(oneDay && oneDay.txCount ? oneDay.txCount : 0) - Number(twoDay && twoDay.txCount ? twoDay.txCount : 0)
      const txnChange = getPercentChange(oneDayTxns, twoDayTxns)

      const liquidityUSD = current ? parseFloat(current[manageUntrackedTVL]) : 0
      const liquidityUSDOneDay = oneDay ? parseFloat(oneDay[manageUntrackedTVL]) : 0
      const liquidityUSDOneWeek = oneWeek ? parseFloat(oneWeek[manageUntrackedTVL]) : 0
      const liquidityUSDOneMonth = oneMonth ? parseFloat(oneMonth[manageUntrackedTVL]) : 0
      const liquidityUSDOneDayChange = getPercentChange(liquidityUSD, liquidityUSDOneDay)
      const liquidityUSDOneWeekChange = getPercentChange(liquidityUSD, liquidityUSDOneWeek)
      const liquidityUSDOneMonthChange = getPercentChange(liquidityUSD, liquidityUSDOneMonth)

      let priceUSD = current ? parseFloat(current?.derivedEth || '0') * bnbPrice : 0
      const priceUSDOneDay = oneDay ? parseFloat(oneDay?.derivedEth || '0') * bnbPrice24H : 0
      const priceUSDOneWeek = oneWeek ? parseFloat(oneWeek?.derivedEth || '0') * bnbPrice24H : 0
      const priceUSDOneMonth = oneMonth ? parseFloat(oneMonth?.derivedEth || '0') * bnbPrice24H : 0

      const priceOneDayChangeUSD = priceUSD > 0 && priceUSDOneDay > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneDay.toString())) : 0
      const priceOneWeekChangeUSD = priceUSD > 0 && priceUSDOneWeek > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneWeek.toString())) : 0
      const priceOneMonthChangeUSD =
        priceUSD > 0 && priceUSDOneMonth > 0 ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneMonth.toString())) : 0

      if (priceUSD < 0.000001) {
        priceUSD = 0
      }
      return {
        id: address,
        name: formatTokenName(address, current && current.name ? current.name : ''),
        symbol: formatTokenSymbol(address, current && current.symbol ? current.symbol : ''),
        decimals: current && current.decimals ? current.decimals : 18,
        volumeOneDayUSD: volumeOneDayUSD < 0.0001 ? 0 : volumeOneDayUSD,
        volumeOneWeekUSD,
        volumeOneMonthUSD,
        volumeOneDayChangeUSD,
        volumeOneWeekChangeUSD,
        volumeOneMonthChangeUSD,
        oneDayTxns,
        twoDayTxns,
        txnChange,
        liquidityUSD,
        liquidityUSDOneDay: liquidityUSD,
        liquidityUSDOneWeek,
        liquidityUSDOneMonth,
        liquidityUSDOneDayChange,
        liquidityUSDOneWeekChange,
        liquidityUSDOneMonthChange,
        priceUSD,
        priceOneDayUSD: priceUSD,
        priceOneWeekUSD: Number(priceUSDOneWeek.toString()),
        priceOneMonthUSD: Number(priceUSDOneMonth.toString()),
        priceOneDayChangeUSD,
        priceOneWeekChangeUSD,
        priceOneMonthChangeUSD,
      }
    })

    return fusionFormatted
      .sort((a, b) => {
        return b.totalLiquidityUSD - a.totalLiquidityUSD
      })
      .reduce((acc, token) => {
        return { ...acc, [token.id]: token }
      }, {})
  } catch (err) {
    console.log('get fusion top tokens :>> ', err)
  }
}

export const getIntervalTokenDataV3 = async (tokenAddress, startTime, interval = 3600, latestBlock) => {
  const utcEndTime = dayjs.utc()
  let time = startTime

  // create an array of hour start times until we reach current hour
  // buffer by half hour to catch case where graph isnt synced to latest block
  const timestamps = []
  while (time < utcEndTime.unix()) {
    timestamps.push(time)
    time += interval
  }

  // backout if invalid timestamp format
  if (timestamps.length === 0) {
    return []
  }

  // once you have all the timestamps, get the blocks for each timestamp in a bulk query
  let blocks
  try {
    blocks = await getBlocksFromTimestamps(timestamps, 100)

    // catch failing case
    if (!blocks || blocks.length === 0) {
      return []
    }

    if (latestBlock) {
      blocks = blocks.filter((b) => {
        return Number(b.number) <= latestBlock
      })
    }

    const result = await splitQuery(PRICES_BY_BLOCK_V3, fusionClient, [tokenAddress], blocks, 50)

    // format token ETH price results
    const values = []
    for (const row in result) {
      const timestamp = row.split('t')[1]
      const derivedEth = Number(result[row]?.derivedEth ?? 0)
      if (timestamp) {
        values.push({
          timestamp,
          derivedEth,
        })
      }
    }

    // go through eth usd prices and assign to original values array
    let index = 0
    for (const brow in result) {
      const timestamp = brow.split('b')[1]
      if (timestamp) {
        values[index].priceUSD = result[brow].bnbPriceUSD * values[index].derivedEth
        index += 1
      }
    }

    const formattedHistory = []

    // for each hour, construct the open and close price
    for (let i = 0; i < values.length - 1; i++) {
      formattedHistory.push({
        timestamp: values[i].timestamp,
        open: Number(values[i].priceUSD),
        close: Number(values[i + 1].priceUSD),
      })
    }

    return formattedHistory
  } catch (e) {
    console.log(e)
    console.log('error fetching blocks')
    return []
  }
}

export async function getTokenInfoV3(bnbPrice, bnbPrice24H, address) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(7, 'day').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(14, 'day').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps(
      [utcOneDayBack, utcTwoDaysBack, utcOneWeekBack, utcTwoWeekBack],
      500,
    )

    let tokens24, tokens48, tokensOneWeek, tokensTwoWeek
    const tokensCurrent = await fetchTokensByTime(undefined, [address])

    if (oneDayBlock && oneDayBlock.number) {
      tokens24 = await fetchTokensByTime(oneDayBlock.number, [address])
    }

    if (twoDayBlock && twoDayBlock.number) {
      tokens48 = await fetchTokensByTime(twoDayBlock.number, [address])
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      tokensOneWeek = await fetchTokensByTime(oneWeekBlock.number, [address])
    }

    if (twoWeekBlock && twoWeekBlock.number) {
      tokensTwoWeek = await fetchTokensByTime(twoWeekBlock.number, [address])
    }

    const parsedTokens = parseTokensData(tokensCurrent)
    const parsedTokens24 = parseTokensData(tokens24)
    const parsedTokens48 = parseTokensData(tokens48)
    const parsedTokensOneWeek = parseTokensData(tokensOneWeek)
    const parsedTokensTwoWeek = parseTokensData(tokensTwoWeek)

    const current = parsedTokens[address]
    const oneDay = parsedTokens24[address]
    const twoDay = parsedTokens48[address]
    const oneWeek = parsedTokensOneWeek[address]
    const twoWeek = parsedTokensTwoWeek[address]

    const manageUntrackedVolume = current ? (+current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
    const manageUntrackedTVL = current ? (+current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''

    let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
      current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0,
      oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0,
      twoDay && twoDay[manageUntrackedVolume] ? Number(twoDay[manageUntrackedVolume]) : 0,
    )

    let [oneWeekVolumeUSD] = get2DayPercentChange(
      current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0,
      oneWeek && oneWeek[manageUntrackedVolume] ? Number(oneWeek[manageUntrackedVolume]) : 0,
      twoWeek && twoWeek[manageUntrackedVolume] ? Number(twoWeek[manageUntrackedVolume]) : 0,
    )

    const tvlUSD = current ? parseFloat(current[manageUntrackedTVL]) : 0
    const tvlUSDChange = getPercentChange(
      current && current[manageUntrackedTVL] ? Number(current[manageUntrackedTVL]) : 0,
      oneDay && oneDay[manageUntrackedTVL] ? Number(oneDay[manageUntrackedTVL]) : 0,
    )

    const tvlToken = current ? parseFloat(current[manageUntrackedTVL]) : 0
    let priceUSD = current ? parseFloat(current.derivedEth) * bnbPrice : 0
    const priceUSDOneDay = oneDay ? parseFloat(oneDay.derivedEth) * bnbPrice24H : 0

    const priceChangeUSD = priceUSD && priceUSDOneDay ? getPercentChange(Number(priceUSD.toString()), Number(priceUSDOneDay.toString())) : 0

    const txCount = current && oneDay ? parseFloat(current.txCount) - parseFloat(oneDay.txCount) : current ? parseFloat(current.txCount) : 0

    const feesUSD = current && oneDay ? parseFloat(current.feesUSD) - parseFloat(oneDay.feesUSD) : current ? parseFloat(current.feesUSD) : 0
    if (oneDayVolumeUSD < 0.000001) {
      oneDayVolumeUSD = 0
    }
    if (oneWeekVolumeUSD < 0.000001) {
      oneWeekVolumeUSD = 0
    }
    if (priceUSD < 0.000001) {
      priceUSD = 0
    }
    if (volumeChangeUSD < 0.0000001) {
      volumeChangeUSD = 0
    }
    return current
      ? {
          id: address,
          name: current ? formatTokenName(address, current.name) : '',
          symbol: current ? formatTokenSymbol(address, current.symbol) : '',
          decimals: current ? current.decimals : 18,
          oneDayVolumeUSD,
          oneWeekVolumeUSD,
          volumeChangeUSD,
          txCount,
          tvlUSD,
          tvlUSDChange,
          feesUSD,
          tvlToken,
          priceUSD,
          priceChangeUSD,
          liquidityChangeUSD: tvlUSDChange,
          totalLiquidityUSD: tvlUSD,
        }
      : undefined
  } catch (err) {
    console.error(err)
  }
}

export async function getTokenInfoTotal(bnbPrice, bnbPrice24H, address) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(7, 'day').unix()
    const utcTwoWeekBack = utcCurrentTime.subtract(14, 'day').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps(
      [utcOneDayBack, utcTwoDaysBack, utcOneWeekBack, utcTwoWeekBack],
      500,
    )

    const tokensCurrent = await fetchTokensByTime(undefined, [address])
    let tokens24, tokens48, tokensOneWeek, tokensTwoWeek

    if (oneDayBlock && oneDayBlock.number) {
      tokens24 = await fetchTokensByTime(oneDayBlock.number, [address])
    }

    if (twoDayBlock && twoDayBlock.number) {
      tokens48 = await fetchTokensByTime(twoDayBlock.number, [address])
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      tokensOneWeek = await fetchTokensByTime(oneWeekBlock.number, [address])
    }

    if (twoWeekBlock && twoWeekBlock.number) {
      tokensTwoWeek = await fetchTokensByTime(twoWeekBlock.number, [address])
    }

    let currentV2, currentDataV2, oneDayDataV2, twoDayDataV2, oneWeekDataV2, twoWeekDataV2

    try {
      currentV2 = await client.query({
        query: TOKEN_INFO(address),
        fetchPolicy: 'network-only',
      })
      currentDataV2 = currentV2 && currentV2.data && currentV2.data.tokens && currentV2.data.tokens.length > 0 ? currentV2.data.tokens[0] : undefined
    } catch (e) {
      console.log('error :>> ', e)
    }

    if (oneDayBlock && oneDayBlock.number) {
      try {
        const oneDayResultV2 = await client.query({
          query: TOKEN_INFO_OLD(oneDayBlock.number, address),
          fetchPolicy: 'network-only',
        })
        oneDayDataV2 =
          oneDayResultV2 && oneDayResultV2.data && oneDayResultV2.data.tokens && oneDayResultV2.data.tokens.length > 0
            ? oneDayResultV2.data.tokens[0]
            : undefined
      } catch (e) {
        console.log('error :>> ', e)
      }
    }

    if (twoDayBlock && twoDayBlock.number) {
      try {
        const twoDayResultV2 = await client.query({
          query: TOKEN_INFO_OLD(twoDayBlock.number, address),
          fetchPolicy: 'network-only',
        })
        twoDayDataV2 =
          twoDayResultV2 && twoDayResultV2.data && twoDayResultV2.data.tokens && twoDayResultV2.data.tokens.length > 0
            ? twoDayResultV2.data.tokens[0]
            : undefined
      } catch (e) {
        console.log('error :>> ', e)
      }
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      try {
        const oneWeekResultV2 = await client.query({
          query: TOKEN_INFO_OLD(oneWeekBlock.number, address),
          fetchPolicy: 'network-only',
        })
        oneWeekDataV2 =
          oneWeekResultV2 && oneWeekResultV2.data && oneWeekResultV2.data.tokens && oneWeekResultV2.data.tokens.length > 0
            ? oneWeekResultV2.data.tokens[0]
            : undefined
      } catch (e) {
        console.log('error :>> ', e)
      }
    }

    if (twoWeekBlock && twoWeekBlock.number) {
      try {
        const twoWeekResultV2 = await client.query({
          query: TOKEN_INFO_OLD(twoWeekBlock.number, address),
          fetchPolicy: 'network-only',
        })
        twoWeekDataV2 =
          twoWeekResultV2 && twoWeekResultV2.data && twoWeekResultV2.data.tokens && twoWeekResultV2.data.tokens.length > 0
            ? twoWeekResultV2.data.tokens[0]
            : undefined
      } catch (e) {
        console.log('error :>> ', e)
      }
    }

    const parsedTokens = parseTokensData(tokensCurrent)
    const parsedTokens24 = parseTokensData(tokens24)
    const parsedTokens48 = parseTokensData(tokens48)
    const parsedTokensOneWeek = parseTokensData(tokensOneWeek)
    const parsedTokensTwoWeek = parseTokensData(tokensTwoWeek)

    const current = parsedTokens[address]
    const oneDay = parsedTokens24[address]
    const twoDay = parsedTokens48[address]
    const oneWeek = parsedTokensOneWeek[address]
    const twoWeek = parsedTokensTwoWeek[address]

    if (
      Number(oneDayDataV2?.totalLiquidity ?? 0) === Number(currentDataV2?.totalLiquidity ?? 0) &&
      Number(oneDayDataV2?.tradeVolume ?? 0) === Number(currentDataV2?.tradeVolume ?? 0) &&
      Number(oneDayDataV2?.derivedETH ?? 0) === Number(currentDataV2?.derivedETH ?? 0)
    ) {
      oneDayDataV2 = null
    }

    if (
      Number(twoDayDataV2?.totalLiquidity ?? 0) === Number(currentDataV2?.totalLiquidity ?? 0) &&
      Number(twoDayDataV2?.tradeVolume ?? 0) === Number(currentDataV2?.tradeVolume ?? 0) &&
      Number(twoDayDataV2?.derivedETH ?? 0) === Number(currentDataV2?.derivedETH ?? 0)
    ) {
      twoDayDataV2 = null
    }

    if (
      Number(oneWeekDataV2?.totalLiquidity ?? 0) === Number(currentDataV2?.totalLiquidity ?? 0) &&
      Number(oneWeekDataV2?.tradeVolume ?? 0) === Number(currentDataV2?.tradeVolume ?? 0) &&
      Number(oneWeekDataV2?.derivedETH ?? 0) === Number(currentDataV2?.derivedETH ?? 0)
    ) {
      oneWeekDataV2 = null
    }

    if (
      Number(twoWeekDataV2?.totalLiquidity ?? 0) === Number(currentDataV2?.totalLiquidity ?? 0) &&
      Number(twoWeekDataV2?.tradeVolume ?? 0) === Number(currentDataV2?.tradeVolume ?? 0) &&
      Number(twoWeekDataV2?.derivedETH ?? 0) === Number(currentDataV2?.derivedETH ?? 0)
    ) {
      twoWeekDataV2 = null
    }

    const manageUntrackedVolume = current ? (+current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
    const manageUntrackedTVL = current ? (+current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''

    const currentVolumeV3 = current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0

    const oneDayVolumeV3 = oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0

    const twoDayVolumeV3 = twoDay && twoDay[manageUntrackedVolume] ? Number(twoDay[manageUntrackedVolume]) : 0

    const oneWeekVolumeV3 = oneWeek && oneWeek[manageUntrackedVolume] ? Number(oneWeek[manageUntrackedVolume]) : 0

    const twoWeekVolumeV3 = twoWeek && twoWeek[manageUntrackedVolume] ? Number(twoWeek[manageUntrackedVolume]) : 0

    const currentVolumeV2 = currentDataV2 && currentDataV2.tradeVolumeUSD ? Number(currentDataV2.tradeVolumeUSD) : 0

    const oneDayVolumeV2 = oneDayDataV2 && oneDayDataV2.tradeVolumeUSD ? Number(oneDayDataV2.tradeVolumeUSD) : 0

    const twoDayVolumeV2 = twoDayDataV2 && twoDayDataV2.tradeVolumeUSD ? Number(twoDayDataV2.tradeVolumeUSD) : 0

    const oneWeekVolumeV2 = oneWeekDataV2 && oneWeekDataV2.tradeVolumeUSD ? Number(oneWeekDataV2.tradeVolumeUSD) : 0

    const twoWeekVolumeV2 = twoWeekDataV2 && twoWeekDataV2.tradeVolumeUSD ? Number(twoWeekDataV2.tradeVolumeUSD) : 0

    let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
      currentVolumeV3 + currentVolumeV2,
      oneDayVolumeV3 + oneDayVolumeV2,
      twoDayVolumeV3 + twoDayVolumeV2,
    )

    let [oneWeekVolumeUSD] = get2DayPercentChange(currentVolumeV3 + currentVolumeV2, oneWeekVolumeV3 + oneWeekVolumeV2, twoWeekVolumeV3 + twoWeekVolumeV2)

    const currentTVL = current && current[manageUntrackedTVL] ? Number(current[manageUntrackedTVL]) : 0

    const oneDayTVL = oneDay && oneDay[manageUntrackedTVL] ? Number(oneDay[manageUntrackedTVL]) : 0

    const currentTVLV2 = currentDataV2 ? Number(currentDataV2.totalLiquidity ?? 0) * bnbPrice * Number(currentDataV2.derivedETH ?? 0) : 0

    const oneDayTVLV2 = oneDayDataV2 ? Number(oneDayDataV2.totalLiquidity ?? 0) * bnbPrice24H * Number(oneDayDataV2.derivedETH ?? 0) : 0

    const tvlUSD = currentTVL + currentTVLV2
    const tvlUSDChange = getPercentChange(currentTVL + currentTVLV2, oneDayTVL + oneDayTVLV2)

    const tvlToken = currentTVL + currentTVLV2

    const priceUSDV3 = current && current.derivedEth ? Number(current.derivedEth) * bnbPrice : 0
    const priceUSDOneDayV3 = oneDay && oneDay.derivedEth ? Number(oneDay.derivedEth) * bnbPrice24H : 0

    const priceUSDV2 = currentDataV2 && currentDataV2.derivedETH ? Number(currentDataV2.derivedETH) * bnbPrice : 0
    const priceUSDOneDayV2 = oneDayDataV2 && oneDayDataV2.derivedETH ? Number(oneDayDataV2.derivedETH) * bnbPrice24H : 0

    let priceUSD = priceUSDV2 > 0 ? priceUSDV2 : priceUSDV3
    const priceUSDOneDay = priceUSDOneDayV2 > 0 ? priceUSDOneDayV2 : priceUSDOneDayV3

    const priceChangeUSD = getPercentChange(priceUSD, priceUSDOneDay)

    const feesCurrentV3 = current && current.feesUSD ? Number(current.feesUSD) : 0
    const feesOneDayV3 = oneDay && oneDay.feesUSD ? Number(oneDay.feesUSD) : 0
    const feesCurrentV2 = currentVolumeV2 * FEEPERCENT
    const feesOneDayV2 = oneDayVolumeV2 * FEEPERCENT

    const feesUSD = feesCurrentV3 + feesCurrentV2 - feesOneDayV3 - feesOneDayV2

    const tokenCurrent = current ?? currentV2
    if (oneDayVolumeUSD < 0.000001) {
      oneDayVolumeUSD = 0
    }
    if (volumeChangeUSD < 0.000001) {
      volumeChangeUSD = 0
    }
    if (oneWeekVolumeUSD < 0.000001) {
      oneWeekVolumeUSD = 0
    }
    if (oneWeekVolumeUSD < 0.000001) {
      oneWeekVolumeUSD = 0
    }
    if (priceUSD < 0.000001) {
      priceUSD = 0
    }
    return tokenCurrent
      ? {
          id: address,
          name: tokenCurrent ? formatTokenName(address, tokenCurrent.name) : '',
          symbol: tokenCurrent ? formatTokenSymbol(address, tokenCurrent.symbol) : '',
          decimals: tokenCurrent ? tokenCurrent.decimals : 18,
          oneDayVolumeUSD,
          volumeChangeUSD,
          oneWeekVolumeUSD,
          tvlUSD,
          tvlUSDChange,
          feesUSD,
          tvlToken,
          priceUSD,
          priceChangeUSD,
          liquidityChangeUSD: tvlUSDChange,
          totalLiquidityUSD: tvlUSD,
        }
      : undefined
  } catch (err) {
    console.error(err)
  }
}

export async function getAllTokensFusion() {
  try {
    let allFound = false
    let skipCount = 0
    let tokens = []
    while (!allFound) {
      const result = await fusionClient.query({
        query: ALL_TOKENS_V3,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'network-only',
      })
      tokens = tokens.concat(result?.data?.tokens)
      if (result?.data?.tokens?.length < 10 || tokens.length > 10) {
        allFound = true
      }
      skipCount = skipCount += 10
    }
    return tokens
  } catch (e) {
    console.log(e)
  }
}

export const getTokenChartDataFusion = async (tokenAddress) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(3, 'month')
  const startTime = utcStartTime.startOf('minute').unix() - 1
  try {
    let allFound = false
    let skip = 0
    while (!allFound) {
      const result = await fusionClient.query({
        query: TOKEN_CHART_V3,
        variables: {
          startTime: startTime,
          tokenAddr: tokenAddress.toLowerCase(),
          skip,
        },
        fetchPolicy: 'network-only',
      })
      if (result.data.tokenDayDatas.length < 100) {
        allFound = true
      }
      skip += 100
      data = data.concat(result.data.tokenDayDatas)
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = getSecondsOneDay()

    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.volumeUSD)
      dayData.totalLiquidityUSD = Number(dayData.totalValueLockedUSD)
    })

    // fill in empty days
    let timestamp = data[0] && data[0].date ? data[0].date : startTime
    let latestLiquidityUSD = data[0] && data[0].totalValueLockedUSD
    let latestPriceUSD = data[0] && data[0].priceUSD
    let index = 1
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay
      const currentDayIndex = (nextDay / oneDay).toFixed(0)
      if (!dayIndexSet.has(currentDayIndex)) {
        data.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
        })
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalValueLockedUSD
        latestPriceUSD = dayIndexArray[index].priceUSD
        index = index + 1
      }
      timestamp = nextDay
    }
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))

    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      // weeklyData[_start].weeklyLiquidityUSD = (weeklyData[_start].weeklyLiquidityUSD ?? 0) + Number(data[i].totalLiquidityUSD)
      weeklyData[_start].weeklyLiquidityUSD = calcWeeklyData(weeklyData[_start].weeklyLiquidityUSD, data[i].totalLiquidityUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyPriceUSD = calcWeeklyData(weeklyData[_start].weeklyPriceUSD, data[i].priceUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export const getTokenChartDataTotal = async (tokenAddress) => {
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(1, 'month')
  const startTime = utcStartTime.startOf('minute').unix() - 1
  const [v1Data, fusionData] = await Promise.all([getTokenChartData(tokenAddress, startTime), getTokenChartDataFusion(tokenAddress, startTime)])
  const totalData = fusionData.map((item) => {
    const v1Item = v1Data.find((v2Item) => v2Item.date === item.date)
    return {
      ...item,
      dailyVolumeUSD: (item && item.dailyVolumeUSD ? Number(item.dailyVolumeUSD) : 0) + (v1Item && v1Item.dailyVolumeUSD ? Number(v1Item.dailyVolumeUSD) : 0),
      priceUSD: item && item.priceUSD ? Number(item.priceUSD) : v1Item && v1Item.priceUSD ? Number(v1Item.priceUSD) : 0,
      totalLiquidityUSD:
        (item && item.totalLiquidityUSD ? Number(item.totalLiquidityUSD) : 0) + (v1Item && v1Item.totalLiquidityUSD ? Number(v1Item.totalLiquidityUSD) : 0),
    }
  })
  return {
    [AnalyticVersions.v1]: v1Data,
    [AnalyticVersions.fusion]: fusionData,
    [AnalyticVersions.total]: totalData,
  }
}

export async function isV3TokenExists(tokenAddress) {
  try {
    const token = await fusionClient.query({
      query: IS_TOKEN_EXISTS_V3(tokenAddress.toLowerCase()),
    })

    if (token.errors) {
      return false
    }
    return token.data.token
  } catch {
    return false
  }
}

export async function getTopPairsTotal(count) {
  try {
    const [v1Pairs, fusionPairs] = await Promise.all([showV1Analytics ? getTopPairsV1(count) : [], getTopPairsFusion(count)])

    return v1Pairs
      .concat(fusionPairs)
      .filter((item) => !!item)
      .sort((a, b) => {
        return b.totalValueLockedUSD - a.totalValueLockedUSD
      })
  } catch (err) {
    console.log(err)
  }
}

export async function getTopPairsFusion(count) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcOneMonthBack = utcCurrentTime.subtract(1, 'month').unix()

    const [oneDayBlock, oneWeekBlock, oneMonthBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcOneWeekBack, utcOneMonthBack], 500)

    const fusionPairsIds = await fusionClient.query({
      query: TOP_POOLS_V3(count),
      fetchPolicy: 'network-only',
    })

    const fusionPairsAddresses = fusionPairsIds.data.pools.map((el) => el.id)
    const [pairsCurrent, pairs24, pairsWeek, pairsMonth] = await Promise.all([
      fetchPairsByTime(undefined, fusionPairsAddresses),
      fetchPairsByTime(oneDayBlock.number, fusionPairsAddresses),
      fetchPairsByTime(oneWeekBlock.number, fusionPairsAddresses),
      fetchPairsByTime(oneMonthBlock.number, fusionPairsAddresses),
    ])

    const parsedPairs = parsePairsData(pairsCurrent)
    const parsedPairs24 = parsePairsData(pairs24)
    const parsedPairsWeek = parsePairsData(pairsWeek)
    const parsedPairsMonth = parsePairsData(pairsMonth)

    const formattedV3 = fusionPairsAddresses.map((address) => {
      const current = parsedPairs[address]
      const oneDay = parsedPairs24[address]
      const week = parsedPairsWeek[address]
      const month = parsedPairsMonth[address]

      const manageUntrackedVolume = current && current.volumeUSD && Number(current.volumeUSD) <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD'

      const manageUntrackedTVL =
        current && current.totalValueLockedUSD && Number(current.totalValueLockedUSD) <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD'

      const v3CurrentVolumeUSD = current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0
      const v3OneDayVolumeUSD = oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0
      const v3WeekVolumeUSD = week && week[manageUntrackedVolume] ? Number(week[manageUntrackedVolume]) : 0
      const v3MonthVolumeUSD = month && month[manageUntrackedVolume] ? Number(month[manageUntrackedVolume]) : 0
      const oneDayVolumeUSD = v3CurrentVolumeUSD - v3OneDayVolumeUSD
      const oneWeekVolumeUSD = v3CurrentVolumeUSD - v3WeekVolumeUSD
      const oneMonthVolumeUSD = v3CurrentVolumeUSD - v3MonthVolumeUSD

      const currentFees = current && current['feesUSD'] ? Number(current['feesUSD']) : 0
      const oneDayFees = oneDay && oneDay['feesUSD'] ? Number(oneDay['feesUSD']) : 0
      const oneWeekFees = week && week['feesUSD'] ? Number(week['feesUSD']) : 0
      const oneMonthFees = month && month['feesUSD'] ? Number(month['feesUSD']) : 0
      const oneDayFeesUSD = currentFees - oneDayFees
      const oneWeekFeesUSD = currentFees - oneWeekFees
      const oneMonthFeesUSD = currentFees - oneMonthFees

      const v3CurrentTVL = current && current[manageUntrackedTVL] ? Number(current[manageUntrackedTVL]) : 0
      const v3OneDayTVL = oneDay && oneDay[manageUntrackedTVL] ? Number(oneDay[manageUntrackedTVL]) : 0
      const v3OneWeekTVL = week && week[manageUntrackedTVL] ? Number(week[manageUntrackedTVL]) : 0
      const v3OneMonthTVL = week && week[manageUntrackedTVL] ? Number(week[manageUntrackedTVL]) : 0
      const tvlUSD = v3CurrentTVL
      const tvlOneWeekUSD = v3OneWeekTVL
      const tvlOneMonthUSD = v3OneMonthTVL
      const tvlUSDChange = getPercentChange(tvlUSD, v3OneDayTVL)

      return current
        ? {
            isFusion: true,
            token0: current.token0,
            token1: current.token1,
            reserve0: current.totalValueLockedToken0,
            reserve1: current.totalValueLockedToken1,
            fee: current.fee,
            id: address,
            oneDayVolumeUSD,
            oneWeekVolumeUSD,
            oneMonthVolumeUSD,
            oneDayFeesUSD,
            oneWeekFeesUSD,
            oneMonthFeesUSD,
            trackedReserveUSD: tvlUSD,
            tvlUSDChange,
            totalValueLockedUSD: tvlUSD,
            tvlOneWeekUSD,
            tvlOneMonthUSD,
          }
        : undefined
    })

    return formattedV3
  } catch (err) {
    console.log(err)
  }
}

export async function getTopPairsV1(count) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDayBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcTwoDayBack, utcOneWeekBack], 500)

    const v1PairIds = await client.query({
      query: PAIRS_CURRENT_QUICK(count),
      fetchPolicy: 'network-only',
    })

    const v1PairsAddresses = v1PairIds.data.pairs.map((el) => el.id)

    const [v1PairsResult, v1OneDayResult, v1TwoDayResult, v1OneWeekResult] = await Promise.all([
      client.query({
        query: PAIRS_BULK1,
        variables: {
          allPairs: v1PairsAddresses,
        },
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: PAIRS_HISTORICAL_BULK(oneDayBlock?.number, v1PairsAddresses),
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: PAIRS_HISTORICAL_BULK(twoDayBlock?.number, v1PairsAddresses),
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: PAIRS_HISTORICAL_BULK(oneWeekBlock?.number, v1PairsAddresses),
        fetchPolicy: 'network-only',
      }),
    ])
    const v1PairsCurrent =
      v1PairsResult && v1PairsResult.data && v1PairsResult.data.pairs && v1PairsResult.data.pairs.length > 0 ? v1PairsResult.data.pairs : []
    const v1PairsOneDay =
      v1OneDayResult && v1OneDayResult.data && v1OneDayResult.data.pairs && v1OneDayResult.data.pairs.length > 0 ? v1OneDayResult.data.pairs : []
    const v1PairsTwoDay =
      v1TwoDayResult && v1TwoDayResult.data && v1TwoDayResult.data.pairs && v1TwoDayResult.data.pairs.length > 0 ? v1TwoDayResult.data.pairs : []
    const v1PairsOneWeek =
      v1OneWeekResult && v1OneWeekResult.data && v1OneWeekResult.data.pairs && v1OneWeekResult.data.pairs.length > 0 ? v1OneWeekResult.data.pairs : []

    const parsedPairsV2 = parsePairsData(v1PairsCurrent)
    const parsedPairs24V2 = parsePairsData(v1PairsOneDay)
    const parsedPairs48V2 = parsePairsData(v1PairsTwoDay)
    const parsedPairsWeekV2 = parsePairsData(v1PairsOneWeek)

    const formattedV2 = v1PairsAddresses.map((address) => {
      const v2Current = parsedPairsV2[address]
      const v2OneDay = parsedPairs24V2[address]
      const v2TwoDay = parsedPairs48V2[address]
      const v2OneWeek = parsedPairsWeekV2[address]

      const v2CurrentVolumeUSD = v2Current && v2Current.volumeUSD ? Number(v2Current.volumeUSD) : 0
      const v2OneDayVolumeUSD = v2OneDay && v2OneDay.volumeUSD ? Number(v2OneDay.volumeUSD) : 0
      const v2TwoDayVolumeUSD = v2TwoDay && v2TwoDay.volumeUSD ? Number(v2TwoDay.volumeUSD) : 0
      const v2WeekVolumeUSD = v2OneWeek && v2OneWeek.volumeUSD ? Number(v2OneWeek.volumeUSD) : 0
      const oneDayVolumeUSD = v2CurrentVolumeUSD - v2OneDayVolumeUSD
      const prevVolumeUSD = v2OneDayVolumeUSD - v2TwoDayVolumeUSD

      const oneWeekVolumeUSD = v2CurrentVolumeUSD - v2WeekVolumeUSD

      const v2CurrentTVL = v2Current
        ? v2Current.trackedReserveUSD
          ? Number(v2Current.trackedReserveUSD)
          : v2Current.reserveUSD
          ? Number(v2Current.reserveUSD)
          : 0
        : 0

      const v2OneDayTVL = v2OneDay
        ? v2OneDay.trackedReserveUSD
          ? Number(v2OneDay.trackedReserveUSD)
          : v2OneDay.reserveUSD
          ? Number(v2OneDay.reserveUSD)
          : 0
        : 0

      const tvlUSD = v2CurrentTVL
      const tvlUSDChange = getPercentChange(tvlUSD, v2OneDayTVL)

      return v2Current
        ? {
            isFusion: false,
            token0: v2Current.token0,
            token1: v2Current.token1,
            reserve0: v2Current.reserve0,
            reserve1: v2Current.reserve1,
            oneDayFeesUSD: oneDayVolumeUSD * (v2Current.isStable ? STABLE_FEE : VOLATILE_FEE),
            prevFeesUSD: prevVolumeUSD * (v2Current.isStable ? STABLE_FEE : VOLATILE_FEE),
            isStable: v2Current.isStable,
            id: address,
            oneDayVolumeUSD,
            oneWeekVolumeUSD,
            trackedReserveUSD: tvlUSD,
            tvlUSDChange,
            totalValueLockedUSD: tvlUSD,
          }
        : undefined
    })

    return formattedV2
  } catch (err) {
    console.log(err)
  }
}

export async function getTopPairsFusionByToken(tokenAddress) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()

    const [oneDayBlock, oneWeekBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcOneWeekBack], undefined)

    const pairsAddresses = await getTokenPairsFusion(tokenAddress)

    const pairsCurrent = await fetchPairsByTime(undefined, pairsAddresses)

    let pairs24, pairsWeek

    if (oneDayBlock && oneDayBlock.number) {
      pairs24 = await fetchPairsByTime(oneDayBlock.number, pairsAddresses)
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      pairsWeek = await fetchPairsByTime(oneWeekBlock.number, pairsAddresses)
    }

    const parsedPairs = parsePairsData(pairsCurrent)
    const parsedPairs24 = parsePairsData(pairs24)
    const parsedPairsWeek = parsePairsData(pairsWeek)

    const formatted = pairsAddresses.map((address) => {
      const current = parsedPairs[address]
      const oneDay = parsedPairs24[address]
      const week = parsedPairsWeek[address]

      if (!current) return

      const manageUntrackedVolume = +current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD'

      const manageUntrackedTVL = +current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD'

      const currentVolume = current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0
      const oneDayVolume = oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0
      let oneDayVolumeUSD = currentVolume - oneDayVolume

      let oneWeekVolumeUSD = week
        ? parseFloat(current[manageUntrackedVolume]) - parseFloat(week[manageUntrackedVolume])
        : parseFloat(current[manageUntrackedVolume])

      if (oneDayVolumeUSD < 0.000001) {
        oneDayVolumeUSD = 0
      }

      if (oneWeekVolumeUSD < 0.000001) {
        oneWeekVolumeUSD = 0
      }

      const currentFees = current && current['feesUSD'] ? Number(current['feesUSD']) : 0

      const oneDayFees = oneDay && oneDay['feesUSD'] ? Number(oneDay['feesUSD']) : 0

      const oneDayFeesUSD = currentFees - oneDayFees

      let tvlUSD = current && current[manageUntrackedTVL] ? parseFloat(current[manageUntrackedTVL]) : 0

      const oneDayTVLUSD = oneDay && oneDay[manageUntrackedTVL] ? oneDay[manageUntrackedTVL] : 0
      let tvlUSDChange = getPercentChange(tvlUSD, oneDayTVLUSD)

      if (tvlUSD < 0.000001) {
        tvlUSD = 0
      }
      if (tvlUSDChange < 0.000001) {
        tvlUSDChange = 0
      }

      return {
        isFusion: true,
        token0: current.token0,
        token1: current.token1,
        fee: current.fee,
        id: address,
        oneDayVolumeUSD,
        oneWeekVolumeUSD,
        oneDayFeesUSD,
        trackedReserveUSD: tvlUSD,
        tvlUSDChange,
        totalValueLockedUSD: tvlUSD,
      }
    })

    return formatted
  } catch (err) {
    console.error(err)
  }
}

export async function getTopPairsTotalByToken(tokenAddress) {
  try {
    const [fusionPairsAddresses, v1PairsAddresses] = await Promise.all([getTokenPairsFusion(tokenAddress), getTokenPairsV1(tokenAddress)])
    return {
      [AnalyticVersions.v1]: v1PairsAddresses,
      [AnalyticVersions.fusion]: fusionPairsAddresses,
      [AnalyticVersions.total]: v1PairsAddresses.concat(fusionPairsAddresses),
    }
  } catch (err) {
    console.error(err)
  }
}

export async function getTopPairsFusionByTokens(tokenAddress, tokenAddress1) {
  try {
    const utcCurrentTime = dayjs()

    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcTwoDaysBack, utcOneWeekBack], 500)

    const topPairsIds = await fusionClient.query({
      query: TOP_POOLS_V3_TOKENS(tokenAddress, tokenAddress1),
      fetchPolicy: 'network-only',
    })

    const pairsAddresses = topPairsIds.data.pools0
      .concat(topPairsIds.data.pools1)
      .concat(topPairsIds.data.pools2)
      .concat(topPairsIds.data.pools3)
      .concat(topPairsIds.data.pools4)
      .map((el) => el.id)

    const pairsCurrent = await fetchPairsByTime(undefined, pairsAddresses)

    let pairs24, pairs48, pairsWeek

    if (oneDayBlock && oneDayBlock.number) {
      pairs24 = await fetchPairsByTime(oneDayBlock.number, pairsAddresses)
    }

    if (twoDayBlock && twoDayBlock.number) {
      pairs48 = await fetchPairsByTime(twoDayBlock.number, pairsAddresses)
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      pairsWeek = await fetchPairsByTime(oneWeekBlock.number, pairsAddresses)
    }

    const parsedPairs = parsePairsData(pairsCurrent)
    const parsedPairs24 = parsePairsData(pairs24)
    const parsedPairs48 = parsePairsData(pairs48)
    const parsedPairsWeek = parsePairsData(pairsWeek)

    const formatted = pairsAddresses.map((address) => {
      const current = parsedPairs[address]
      const oneDay = parsedPairs24[address]
      const twoDay = parsedPairs48[address]
      const week = parsedPairsWeek[address]

      if (!current) return

      const manageUntrackedVolume = +current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD'

      const manageUntrackedTVL = +current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD'

      const [oneDayVolumeUSD, oneDayVolumeChangeUSD] =
        oneDay && twoDay
          ? get2DayPercentChange(current[manageUntrackedVolume], oneDay[manageUntrackedVolume], twoDay[manageUntrackedVolume])
          : oneDay
          ? [parseFloat(current[manageUntrackedVolume]) - parseFloat(oneDay[manageUntrackedVolume]), 0]
          : [parseFloat(current[manageUntrackedVolume]), 0]

      const oneWeekVolumeUSD = week
        ? parseFloat(current[manageUntrackedVolume]) - parseFloat(week[manageUntrackedVolume])
        : parseFloat(current[manageUntrackedVolume])

      const tvlUSD = parseFloat(current[manageUntrackedTVL])
      const tvlUSDChange = getPercentChange(current[manageUntrackedTVL], oneDay ? oneDay[manageUntrackedTVL] : undefined)

      const currentFees = current && current['feesUSD'] ? Number(current['feesUSD']) : 0

      const oneDayFees = oneDay && oneDay['feesUSD'] ? Number(oneDay['feesUSD']) : 0

      const oneDayFeesUSD = currentFees - oneDayFees

      return {
        token0: current.token0,
        token1: current.token1,
        fee: current.fee,
        id: address,
        oneDayVolumeUSD,
        oneDayVolumeChangeUSD,
        oneWeekVolumeUSD,
        oneDayFeesUSD,
        trackedReserveUSD: tvlUSD,
        tvlUSDChange,
        totalValueLockedUSD: current[manageUntrackedTVL],
      }
    })

    return formatted
  } catch (err) {
    console.error(err)
  }
}

export async function getPairInfoV3(address) {
  try {
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()

    const [oneDayBlock, twoDayBlock, oneWeekBlock] = await getBlocksFromTimestamps([utcOneDayBack, utcTwoDaysBack, utcOneWeekBack], 500)

    const pairsCurrent = await fetchPairsByTime(undefined, [address])
    let pairs24, pairs48, pairsWeek

    if (oneDayBlock && oneDayBlock.number) {
      pairs24 = await fetchPairsByTime(oneDayBlock.number, [address])
    }

    if (twoDayBlock && twoDayBlock.number) {
      pairs48 = await fetchPairsByTime(twoDayBlock.number, [address])
    }

    if (oneWeekBlock && oneWeekBlock.number) {
      pairsWeek = await fetchPairsByTime(oneWeekBlock.number, [address])
    }

    const parsedPairs = parsePairsData(pairsCurrent)
    const parsedPairs24 = parsePairsData(pairs24)
    const parsedPairs48 = parsePairsData(pairs48)
    const parsedPairsWeek = parsePairsData(pairsWeek)

    const current = parsedPairs[address]
    const oneDay = parsedPairs24[address]
    const twoDay = parsedPairs48[address]
    const week = parsedPairsWeek[address]

    const manageUntrackedVolume = current ? (+current.volumeUSD <= 1 ? 'untrackedVolumeUSD' : 'volumeUSD') : ''
    const manageUntrackedTVL = current ? (+current.totalValueLockedUSD <= 1 ? 'totalValueLockedUSDUntracked' : 'totalValueLockedUSD') : ''

    const currentVolume = current && current[manageUntrackedVolume] ? Number(current[manageUntrackedVolume]) : 0
    const oneDayVolume = oneDay && oneDay[manageUntrackedVolume] ? Number(oneDay[manageUntrackedVolume]) : 0
    const twoDayVolume = twoDay && twoDay[manageUntrackedVolume] ? Number(twoDay[manageUntrackedVolume]) : 0
    const oneWeekVolume = week && week[manageUntrackedVolume] ? Number(week[manageUntrackedVolume]) : 0

    let [oneDayVolumeUSD, oneDayVolumeChangeUSD] = get2DayPercentChange(currentVolume, oneDayVolume, twoDayVolume)
    if (oneDayVolumeUSD < 0.000001) {
      oneDayVolumeUSD = 0
    }
    if (oneDayVolumeChangeUSD < 0.000001) {
      oneDayVolumeChangeUSD = 0
    }

    let oneWeekVolumeUSD = currentVolume - oneWeekVolume
    if (oneWeekVolumeUSD < 0.000001) {
      oneWeekVolumeUSD = 0
    }

    const currentTVL = current && current[manageUntrackedTVL] ? Number(current[manageUntrackedTVL]) : 0
    const oneDayTVL = oneDay && oneDay[manageUntrackedTVL] ? Number(oneDay[manageUntrackedTVL]) : 0
    let tvlUSD = currentTVL
    if (tvlUSD < 0.000001) {
      tvlUSD = 0
    }
    const tvlUSDChange = getPercentChange(currentTVL, oneDayTVL)

    const currentFees = current && current.feesUSD ? Number(current.feesUSD) : 0
    const oneDayFees = oneDay && oneDay.feesUSD ? Number(oneDay.feesUSD) : 0
    let feesUSD = currentFees
    if (feesUSD < 0.000001) {
      feesUSD = 0
    }
    let feesUSDOneDay = currentFees - oneDayFees
    if (feesUSDOneDay < 0.000001) {
      feesUSDOneDay = 0
    }
    const feesUSDChange = getPercentChange(currentFees, oneDayFees)

    const poolFeeChange = getPercentChange(current ? current.fee : undefined, oneDay ? oneDay.fee : undefined)

    const token0PriceChange = getPercentChange(current ? current.token0Price : undefined, oneDay ? oneDay.token0Price : undefined)

    const token1PriceChange = getPercentChange(current ? current.token1Price : undefined, oneDay ? oneDay.token1Price : undefined)

    const aprPercent = 0
    const farmingApr = 0

    return [
      current
        ? {
            token0: {
              ...current.token0,
              symbol: formatTokenSymbol(current.token0.id, current.token0.symbol),
            },
            token1: {
              ...current.token1,
              symbol: formatTokenSymbol(current.token1.id, current.token1.symbol),
            },
            fee: current.fee,
            id: address,
            oneDayVolumeUSD,
            oneDayVolumeChangeUSD,
            oneWeekVolumeUSD,
            trackedReserveUSD: tvlUSD,
            tvlUSDChange,
            reserve0: current.totalValueLockedToken0,
            reserve1: current.totalValueLockedToken1,
            totalValueLockedUSD: current[manageUntrackedTVL],
            apr: aprPercent,
            farmingApr: farmingApr,
            volumeChangeUSD: oneDayVolumeChangeUSD,
            liquidityChangeUSD: tvlUSDChange,
            feesUSD,
            feesUSDOneDay,
            feesUSDChange,
            poolFeeChange,
            token0Price: Number(current.token0Price).toFixed(3),
            token0PriceChange,
            token1Price: Number(current.token1Price).toFixed(3),
            token1PriceChange,
          }
        : undefined,
    ]
  } catch (err) {
    console.error(err)
  }
}

export async function getAllPairsFusion() {
  try {
    let allFound = false
    let pairs = []
    let skipCount = 0
    while (!allFound) {
      const result = await fusionClient.query({
        query: ALL_PAIRS_V3,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: 'network-only',
      })
      skipCount = skipCount + 10
      pairs = pairs.concat(result?.data?.pools)
      if (result?.data?.pools.length < 10 || pairs.length > 10) {
        allFound = true
      }
    }
    return pairs
  } catch (e) {
    console.log(e)
  }
}

export const getPairChartDataFusion = async (pairAddress) => {
  let data = []
  const weeklyData = []
  const utcEndTime = dayjs.utc()
  const utcStartTime = utcEndTime.subtract(6, 'month').startOf('minute')
  const startTime = utcStartTime.unix() - 1
  let allFound = false
  let skip = 0
  try {
    while (!allFound) {
      const result = await fusionClient.query({
        query: PAIR_CHART_V3,
        variables: {
          startTime: startTime,
          pairAddress: pairAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      })
      skip += 1000
      data = data.concat(result.data.poolDayDatas)
      if (result.data.poolDayDatas.length < 1000) {
        allFound = true
      }
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = 24 * 60 * 60
    data.forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.dailyVolumeUSD = Number(dayData.volumeUSD)
      dayData.reserveUSD = Number(dayData.tvlUSD)
      dayData.feesUSD = Number(dayData.feesUSD)
      // dayData.token0Price = dayData.token0Price;
      // dayData.token1Price = dayData.token1Price;
    })

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].date ? data[0].date : startTime
      let latestLiquidityUSD = data[0].tvlUSD
      let latestFeesUSD = data[0].feesUSD
      let latestToken0Price = data[0].token0Price
      let latestToken1Price = data[0].token1Price
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dayString: nextDay,
            dailyVolumeUSD: 0,
            reserveUSD: latestLiquidityUSD,
            feesUSD: latestFeesUSD,
            token0Price: latestToken0Price,
            token1Price: latestToken1Price,
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].tvlUSD
          latestFeesUSD = dayIndexArray[index].feesUSD
          latestToken0Price = dayIndexArray[index].token0Price
          latestToken1Price = dayIndexArray[index].token1Price
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    data.pop()
    let _start = -1
    let currentWeek = -1
    data.forEach((entry, i) => {
      const week = dayjs.utc(dayjs.unix(data[i].date)).week()
      if (week !== currentWeek) {
        currentWeek = week
        _start++
      }
      weeklyData[_start] = weeklyData[_start] || {}
      weeklyData[_start].date = data[i].date
      weeklyData[_start].weeklyCount = (weeklyData[_start].weeklyCount ?? 0) + 1
      weeklyData[_start].weeklyTvlUSD = calcWeeklyData(weeklyData[_start].weeklyTvlUSD, data[i].reserveUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyVolumeUSD = calcWeeklyData(weeklyData[_start].weeklyVolumeUSD, data[i].dailyVolumeUSD, weeklyData[_start].weeklyCount)
      weeklyData[_start].weeklyFeesUSD = calcWeeklyData(weeklyData[_start].weeklyFeesUSD, data[i].feesUSD, weeklyData[_start].weeklyCount)
    })
  } catch (e) {
    console.log(e)
  }
  return {
    daily: data,
    weekly: weeklyData,
  }
}

export async function getPairChartFees(address, startTime) {
  let data = []
  const utcEndTime = dayjs.utc()
  let allFound = false
  let skip = 0
  try {
    while (!allFound) {
      const result = await fusionClient.query({
        query: PAIR_FEE_CHART_V3,
        fetchPolicy: 'network-only',
        variables: { address, startTime, skip },
      })
      skip += 1000
      data = data.concat(result.data.feeHourDatas)
      if (result.data.feeHourDatas.length < 1000) {
        allFound = true
      }
    }

    const dayIndexSet = new Set()
    const dayIndexArray = []
    const oneDay = 24 * 60 * 60

    data.reverse().forEach((dayData, i) => {
      // add the day index to the set of days
      dayIndexSet.add((Number(data[i].timestamp) / oneDay).toFixed(0))
      dayIndexArray.push(data[i])
      dayData.fee = Number(dayData.fee) / Number(dayData.changesCount)
      dayData.dayString = Number(dayData.timestamp)
      dayData.date = Number(dayData.timestamp)
    })

    if (data[0]) {
      // fill in empty days
      let timestamp = data[0].timestamp ? Number(data[0].timestamp) : startTime
      let latestFee = data[0].fee
      let index = 1

      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            timestamp: nextDay,
            dayString: nextDay,
            fee: latestFee,
            date: nextDay,
          })
        } else {
          latestFee = dayIndexArray[index].fee
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    data = data.sort((a, b) => (parseInt(a.timestamp) > parseInt(b.timestamp) ? 1 : -1))
  } catch (e) {
    console.log(e)
  }

  return data
}

export async function getLiquidityChart(address) {
  const numSurroundingTicks = 300
  const PRICE_FIXED_DIGITS = 8

  const pool = await fusionClient.query({
    query: PAIRS_FROM_ADDRESSES_V3(undefined, [address]),
  })

  const {
    tick: poolCurrentTick,
    tickSpacing: poolTickSpacing,
    liquidity,
    token0: { id: token0Address, decimals: token0Decimals },
    token1: { id: token1Address, decimals: token1Decimals },
  } = pool.data.pools[0]

  const poolCurrentTickIdx = parseInt(poolCurrentTick)
  const tickSpacing = Number(poolTickSpacing) || 60

  const activeTickIdx = Math.floor(poolCurrentTickIdx / tickSpacing) * tickSpacing

  const tickIdxLowerBound = activeTickIdx - numSurroundingTicks * tickSpacing
  const tickIdxUpperBound = activeTickIdx + numSurroundingTicks * tickSpacing

  async function fetchInitializedTicks(poolAddress, tickIdxLowerBound, tickIdxUpperBound) {
    let surroundingTicks = []
    let surroundingTicksResult = []

    let skip = 0
    do {
      const ticks = await fusionClient.query({
        query: FETCH_TICKS(),
        fetchPolicy: 'cache-first',
        variables: {
          poolAddress,
          tickIdxLowerBound,
          tickIdxUpperBound,
          skip,
        },
      })

      surroundingTicks = ticks.data.ticks
      surroundingTicksResult = surroundingTicksResult.concat(surroundingTicks)
      skip += 1000
    } while (surroundingTicks.length > 0)

    return { ticks: surroundingTicksResult, loading: false, error: false }
  }

  const initializedTicksResult = await fetchInitializedTicks(address, tickIdxLowerBound, tickIdxUpperBound)
  if (initializedTicksResult.error || initializedTicksResult.loading) {
    return {
      error: initializedTicksResult.error,
      loading: initializedTicksResult.loading,
    }
  }

  const { ticks: initializedTicks } = initializedTicksResult

  const tickIdxToInitializedTick = keyBy(initializedTicks, 'tickIdx')

  const token0 = new Token(defaultChainId, token0Address, parseInt(token0Decimals))
  const token1 = new Token(defaultChainId, token1Address, parseInt(token1Decimals))

  let activeTickIdxForPrice = activeTickIdx
  if (activeTickIdxForPrice < TickMath.MIN_TICK) {
    activeTickIdxForPrice = TickMath.MIN_TICK
  }
  if (activeTickIdxForPrice > TickMath.MAX_TICK) {
    activeTickIdxForPrice = TickMath.MAX_TICK
  }

  const activeTickProcessed = {
    liquidityActive: JSBI.BigInt(liquidity),
    tickIdx: activeTickIdx,
    liquidityNet: JSBI.BigInt(0),
    price0: tickToPrice(token0, token1, activeTickIdxForPrice).toFixed(PRICE_FIXED_DIGITS),
    price1: tickToPrice(token1, token0, activeTickIdxForPrice).toFixed(PRICE_FIXED_DIGITS),
    liquidityGross: JSBI.BigInt(0),
  }

  const activeTick = tickIdxToInitializedTick[activeTickIdx]
  if (activeTick) {
    activeTickProcessed.liquidityGross = JSBI.BigInt(activeTick.liquidityGross)
    activeTickProcessed.liquidityNet = JSBI.BigInt(activeTick.liquidityNet)
  }

  const Direction = {
    ASC: 0,
    DESC: 1,
  }

  // Computes the numSurroundingTicks above or below the active tick.
  const computeSurroundingTicks = (activeTickProcessed, tickSpacing, numSurroundingTicks, direction) => {
    let previousTickProcessed = {
      ...activeTickProcessed,
    }

    // Iterate outwards (either up or down depending on 'Direction') from the active tick,
    // building active liquidity for every tick.
    let processedTicks = []
    for (let i = 0; i < numSurroundingTicks; i++) {
      const currentTickIdx = direction == Direction.ASC ? previousTickProcessed.tickIdx + tickSpacing : previousTickProcessed.tickIdx - tickSpacing

      if (currentTickIdx < TickMath.MIN_TICK || currentTickIdx > TickMath.MAX_TICK) {
        break
      }

      const currentTickProcessed = {
        liquidityActive: previousTickProcessed.liquidityActive,
        tickIdx: currentTickIdx,
        liquidityNet: JSBI.BigInt(0),
        price0: tickToPrice(token0, token1, currentTickIdx).toFixed(PRICE_FIXED_DIGITS),
        price1: tickToPrice(token1, token0, currentTickIdx).toFixed(PRICE_FIXED_DIGITS),
        liquidityGross: JSBI.BigInt(0),
      }

      const currentInitializedTick = tickIdxToInitializedTick[currentTickIdx.toString()]
      if (currentInitializedTick) {
        currentTickProcessed.liquidityGross = JSBI.BigInt(currentInitializedTick.liquidityGross)
        currentTickProcessed.liquidityNet = JSBI.BigInt(currentInitializedTick.liquidityNet)
      }

      if (direction == Direction.ASC && currentInitializedTick) {
        currentTickProcessed.liquidityActive = JSBI.add(previousTickProcessed.liquidityActive, JSBI.BigInt(currentInitializedTick.liquidityNet))
      } else if (direction == Direction.DESC && JSBI.notEqual(previousTickProcessed.liquidityNet, JSBI.BigInt(0))) {
        currentTickProcessed.liquidityActive = JSBI.subtract(previousTickProcessed.liquidityActive, previousTickProcessed.liquidityNet)
      }

      processedTicks.push(currentTickProcessed)
      previousTickProcessed = currentTickProcessed
    }

    if (direction == Direction.DESC) {
      processedTicks = processedTicks.reverse()
    }

    return processedTicks
  }

  const subsequentTicks = computeSurroundingTicks(activeTickProcessed, tickSpacing, numSurroundingTicks, Direction.ASC)

  const previousTicks = computeSurroundingTicks(activeTickProcessed, tickSpacing, numSurroundingTicks, Direction.DESC)

  const ticksProcessed = previousTicks.concat(activeTickProcessed).concat(subsequentTicks)

  return {
    ticksProcessed,
    tickSpacing,
    activeTickIdx,
    token0,
    token1,
  }
  // setTicksResult({
  //     ticksProcessed,
  //     tickSpacing,
  //     activeTickIdx,
  //     token0,
  //     token1
  // })
}

export const getPairTransactionsV1 = async (pairAddress) => {
  const transactions = {}
  let newTxns = []

  try {
    let result = await client.query({
      query: FILTERED_TRANSACTIONS,
      variables: {
        allPairs: [pairAddress],
      },
      fetchPolicy: 'no-cache',
    })
    transactions.mints = result.data.mints
    transactions.burns = result.data.burns
    transactions.swaps = result.data.swaps
    if (transactions.mints.length > 0) {
      transactions.mints.map((mint) => {
        let newTxn = {}
        newTxn.hash = mint.transaction.id
        newTxn.timestamp = mint.transaction.timestamp
        newTxn.type = TXN_TYPE.ADD
        newTxn.token0Amount = mint.amount0
        newTxn.token1Amount = mint.amount1
        newTxn.account = mint.to
        newTxn.token0Symbol = updateNameData(mint.pair).token0.symbol
        newTxn.token1Symbol = updateNameData(mint.pair).token1.symbol
        newTxn.amountUSD = mint.amountUSD
        return newTxns.push(newTxn)
      })
    }
    if (transactions.burns.length > 0) {
      transactions.burns.map((burn) => {
        let newTxn = {}
        newTxn.hash = burn.transaction.id
        newTxn.timestamp = burn.transaction.timestamp
        newTxn.type = TXN_TYPE.REMOVE
        newTxn.token0Amount = burn.amount0
        newTxn.token1Amount = burn.amount1
        newTxn.account = burn.sender
        newTxn.token0Symbol = updateNameData(burn.pair).token0.symbol
        newTxn.token1Symbol = updateNameData(burn.pair).token1.symbol
        newTxn.amountUSD = burn.amountUSD
        return newTxns.push(newTxn)
      })
    }
    if (transactions.swaps.length > 0) {
      transactions.swaps.map((swap) => {
        const netToken0 = swap.amount0In - swap.amount0Out
        const netToken1 = swap.amount1In - swap.amount1Out

        let newTxn = {}

        if (netToken0 < 0) {
          newTxn.token0Symbol = updateNameData(swap.pair).token0.symbol
          newTxn.token1Symbol = updateNameData(swap.pair).token1.symbol
          newTxn.token0Amount = Math.abs(netToken0)
          newTxn.token1Amount = Math.abs(netToken1)
        } else if (netToken1 < 0) {
          newTxn.token0Symbol = updateNameData(swap.pair).token1.symbol
          newTxn.token1Symbol = updateNameData(swap.pair).token0.symbol
          newTxn.token0Amount = Math.abs(netToken1)
          newTxn.token1Amount = Math.abs(netToken0)
        }

        newTxn.hash = swap.transaction.id
        newTxn.timestamp = swap.transaction.timestamp
        newTxn.type = TXN_TYPE.SWAP
        newTxn.amountUSD = swap.amountUSD
        newTxn.account = swap.to
        return newTxns.push(newTxn)
      })
    }
  } catch (e) {
    console.log(e)
  }

  return newTxns
}

export async function getPairTransactionsFusion(address) {
  let newTxns = []
  const data = await fusionClient.query({
    query: PAIR_TRANSACTIONS_v3,
    variables: {
      address: address,
    },
    fetchPolicy: 'cache-first',
  })

  data.data.mints.forEach((mint) => {
    let newTxn = {}
    newTxn.hash = mint.transaction.id
    newTxn.timestamp = mint.timestamp
    newTxn.type = TXN_TYPE.ADD
    newTxn.token0Amount = mint.amount0
    newTxn.token1Amount = mint.amount1
    newTxn.account = mint.origin
    newTxn.token0Symbol = formatTokenSymbol(mint.pool.token0.id, mint.pool.token0.symbol)
    newTxn.token1Symbol = formatTokenSymbol(mint.pool.token1.id, mint.pool.token1.symbol)
    newTxn.amountUSD = mint.amountUSD
    newTxns.push(newTxn)
  })
  data.data.burns.forEach((burn) => {
    let newTxn = {}
    newTxn.hash = burn.transaction.id
    newTxn.timestamp = burn.timestamp
    newTxn.type = TXN_TYPE.REMOVE
    newTxn.token0Amount = burn.amount0
    newTxn.token1Amount = burn.amount1
    newTxn.account = burn.owner
    newTxn.token0Symbol = formatTokenSymbol(burn.pool.token0.id, burn.pool.token0.symbol)
    newTxn.token1Symbol = formatTokenSymbol(burn.pool.token1.id, burn.pool.token1.symbol)
    newTxn.amountUSD = burn.amountUSD
    newTxns.push(newTxn)
  })

  data.data.swaps.forEach((swap) => {
    let newTxn = {}
    newTxn.hash = swap.transaction.id
    newTxn.timestamp = swap.timestamp
    newTxn.type = TXN_TYPE.SWAP
    newTxn.account = swap.origin

    if (parseFloat(swap.amount0) < 0) {
      newTxn.token0Amount = Math.abs(swap.amount0)
      newTxn.token1Amount = Math.abs(swap.amount1)
      newTxn.token0Symbol = formatTokenSymbol(swap.pool.token0.id, swap.pool.token0.symbol)
      newTxn.token1Symbol = formatTokenSymbol(swap.pool.token1.id, swap.pool.token1.symbol)
    } else {
      newTxn.token1Amount = Math.abs(swap.amount0)
      newTxn.token0Amount = Math.abs(swap.amount1)
      newTxn.token1Symbol = formatTokenSymbol(swap.pool.token0.id, swap.pool.token0.symbol)
      newTxn.token0Symbol = formatTokenSymbol(swap.pool.token1.id, swap.pool.token1.symbol)
    }
    newTxn.amountUSD = swap.amountUSD
    newTxns.push(newTxn)
  })

  return newTxns
}

export async function getTokenTransactionsFusion(address) {
  try {
    const data = await fusionClient.query({
      query: GLOBAL_TRANSACTIONS_V3,
      variables: {
        address: address,
      },
      fetchPolicy: 'cache-first',
    })

    const mints = data.data.mints.map((m) => {
      return {
        type: TXN_TYPE.ADD,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const burns = data.data.burns.map((m) => {
      return {
        type: TXN_TYPE.REMOVE,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.owner,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const swaps = data.data.swaps.map((m) => {
      return {
        type: TXN_TYPE.SWAP,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    return {
      mints,
      burns,
      swaps,
    }
  } catch {
    return
  }
}

export async function getTokenTransactionsTotal(address) {
  try {
    const fusionData = await fusionClient.query({
      query: GLOBAL_TRANSACTIONS_V3,
      variables: {
        address: address,
      },
      fetchPolicy: 'network-only',
    })

    const formattedPairsV1 = await getTokenPairsV1(address)

    const v1Data = await client.query({
      query: FILTERED_TRANSACTIONS,
      variables: {
        allPairs: formattedPairsV1,
      },
      fetchPolicy: 'network-only',
    })

    const mints0 = fusionData.data.mintsAs0.map((m) => {
      return {
        type: TXN_TYPE.ADD,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })
    const mints1 = fusionData.data.mintsAs1.map((m) => {
      return {
        type: TXN_TYPE.ADD,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const burns0 = fusionData.data.burnsAs0.map((m) => {
      return {
        type: TXN_TYPE.REMOVE,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.owner,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })
    const burns1 = fusionData.data.burnsAs1.map((m) => {
      return {
        type: TXN_TYPE.REMOVE,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.owner,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const swaps0 = fusionData.data.swapsAs0.map((m) => {
      return {
        type: TXN_TYPE.SWAP,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const swaps1 = fusionData.data.swapsAs1.map((m) => {
      return {
        type: TXN_TYPE.SWAP,
        transaction: {
          ...m.transaction,
          timestamp: m.timestamp,
        },
        timestamp: m.timestamp,
        sender: m.origin,
        pair: {
          token0: {
            symbol: formatTokenSymbol(m.pool.token0.id, m.pool.token0.symbol),
            id: m.pool.token0.id,
          },
          token1: {
            symbol: formatTokenSymbol(m.pool.token1.id, m.pool.token1.symbol),
            id: m.pool.token1.id,
          },
        },
        amountUSD: parseFloat(m.amountUSD),
        amount0: parseFloat(m.amount0),
        amount1: parseFloat(m.amount1),
      }
    })

    const mintsV2 = v1Data.data.mints.map((m) => {
      return {
        ...m,
        isV2: true,
      }
    })

    const swapsV2 = v1Data.data.swaps.map((m) => {
      return {
        ...m,
        isV2: true,
      }
    })

    const burnsV2 = v1Data.data.burns.map((m) => {
      return {
        ...m,
        isV2: true,
      }
    })

    return {
      mints: [...mints0, ...mints1, ...mintsV2],
      burns: [...burns0, ...burns1, ...burnsV2],
      swaps: [...swaps0, ...swaps1, ...swapsV2],
    }
  } catch (e) {
    console.log('err', e)
    return
  }
}

export async function isV3PairExists(pairAddress) {
  try {
    const pair = await fusionClient.query({
      query: IS_PAIR_EXISTS_V3(pairAddress.toLowerCase()),
    })

    if (pair.errors) {
      return false
    }
    return pair.data.pool
  } catch {
    return false
  }
}

//Token Helpers

async function fetchTokensByTime(blockNumber, tokenAddresses) {
  try {
    const tokens = await fusionClient.query({
      query: TOKENS_FROM_ADDRESSES_V3(blockNumber, tokenAddresses),
      fetchPolicy: 'network-only',
    })

    return tokens.data.tokens
  } catch (err) {
    console.error('Tokens fetching by time in v3 ' + err)
    return
  }
}

async function fetchTokensByTimeV2(blockNumber, tokenAddresses) {
  try {
    const tokens = await client.query({
      query: TOKENS_FROM_ADDRESSES_V2(blockNumber, tokenAddresses),
      fetchPolicy: 'network-only',
    })

    return tokens.data.tokens
  } catch (err) {
    console.error('Tokens fetching by time in v2 ' + err)
    return
  }
}

function parseTokensData(tokenData) {
  return tokenData
    ? tokenData.reduce((acc, tokenData) => {
        acc[tokenData.id] = tokenData
        return acc
      }, {})
    : {}
}

const WETH_ADDRESSES = ['0x2c1b868d6596a18e32e61b901e4060c872647b6c']

export function formatTokenSymbol(address, symbol) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'ETH'
  } else if (symbol.toLowerCase() === 'mimatic') {
    return 'MAI'
  } else if (symbol.toLowerCase() === 'amaticc') {
    return 'ankrMATIC'
  }
  return symbol
}

export function formatTokenName(address, name) {
  if (WETH_ADDRESSES.includes(address)) {
    return 'Eth'
  }
  return name
}

//Pair helpers

async function fetchPairsByTime(blockNumber, tokenAddresses) {
  try {
    const pairs = await fusionClient.query({
      query: PAIRS_FROM_ADDRESSES_V3(blockNumber, tokenAddresses),
      fetchPolicy: 'network-only',
    })

    return pairs.data.pools
  } catch (err) {
    console.error('Pairs by time fetching ' + err)
    return
  }
}

function parsePairsData(pairData) {
  return pairData
    ? pairData.reduce((accum, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}
}
