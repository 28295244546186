import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const swapxClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_SWAPX_GRAPH,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export default swapxClient
