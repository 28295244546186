import gql from 'graphql-tag'

export const SWAPX_GLOBAL_CHART = gql`
  query swapxDayDatas($startTime: Int!, $skip: Int!) {
    dayDatas(first: 100, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      date
      volumeUSD
      tvlUSD
      feesUSD
    }
  }
`
